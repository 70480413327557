
import React from 'react';
import AppBarComponent from './components/appBar'; 
import { Box, Container, Typography, Grid } from '@mui/material';
import Footer from './components/footer';
import './rpa.css'; // Import the external CSS file
import ContactButton from '../../components/contact-button';

const TextSection = () => {
    return (
        <>
        <Box className="text-section">
            <Container className="normal-text">
                <Typography variant="body" paragraph>
                <br />It's time to replace repetitive manual tasks and processes with simple or advanced RPA:<br /><br />
                    100% accurate workflows on any scale, much faster than a human could, for a fraction of the price.
                    <br /><br /><br />
                </Typography>
            </Container>

            <Container  className="blue-text-section" gutterBottom>
                <p>
                    <span className="primary-blue-rpa bold">30%-35% reduction in entry level roles </span>
                    and increase mid level roles.<br/>
                    <span className="bold">Everest group</span>
                    <br/>
                    <br/>
                    <br/>
                </p>
                <Typography variant="body" paragraph>
                    <span className="primary-blue-rpa bold">Cost reduction of 35%-65% </span>
                    for onshore operations and 10-30% for offshore operations.<br />
                    <span className="bold">Institute for Robotic Process Automation</span>
                    <br/>
                    <br/>
                    <br/>
                </Typography>
                <Typography variant="body" paragraph>
                    <span className="primary-blue-rpa bold">85% </span>
                    of a typical firm’s 900+
                    <span className="primary-blue-rpa bold"> processes can be automated.</span>
                    <br />
                    <span className="primary-blue-rpa bold">110-140m FTEs </span>
                    could be 
                    <span className="primary-blue-rpa bold"> replaced by 2025.</span>
                    <br />
                    <span className="bold">McKinsey & Company</span>
                    <br/>
                </Typography>
            </Container>

            

        </Box>
        <Container className="subtitle">
                <Typography variant="h3" color="var(--primary-blue)" gutterBottom>
                    Connect business applications and automate workflows instantly – UI, documents, images and beyond – using point-and-click solutions
                </Typography>
            </Container>
            <Grid container spacing={4} alignItems="center">
    <Grid item  md={6} className="image-column">
        <img
            src="/images/rpa2.jpg"
            alt="Section Image"
            className="image-padding"
        />
    </Grid>
                <Grid item  md={6} className="text-column">
                    <Box className="text-box">
                        <Typography variant="body" paragraph>
                            RPA can be deployed so quickly and inexpensively in almost any situation and on any platform that it will quickly go from being a differentiator to a standard practice that every organization will need simply to survive.<br /><br /><br />
                            Our RPA experts can help you scope, test and deploy solutions, help manage the people and organizational changes it will bring, as well as guide you through new considerations on governance.<br /><br /><br />
                            Other top benefits of using the MuleSoft RPA tool include:<br /><br />
                            Unlocking data from legacy systems<br />
                            Freeing up workforce by automating manual and repetitive tasks<br />
                            Full control through centrally managed and governed automated processes<br />
                            Seamless integration with MuleSoft APIs, MuleSoft Composer Processes and hundreds of systems
                        </Typography>
                        <Box className="button-container">
                        <ContactButton text="Build your Mulesoft RPA now" mobile={false} backgroundWhite={false} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

const Hero = () => {
    return (
        <Box className="hero-rpa">
            <Box className="hero-banner-rpa">
                <Typography variant="h4" fontWeight="bold" color="var(--primary-blue)">
                    Error prone manual processes that disrupt your business are a thing of the past with Mulesoft RPA
                </Typography>
                <Box className="hero-button-rpa">
                <ContactButton text="Build your Mulesoft RPA now" mobile={false} backgroundWhite={false} />
                </Box>
            </Box>
        </Box>
    );
};

const RPA = () => {
    return (
        <div>
            <AppBarComponent />
            <Hero />
            <TextSection />
            <Footer />
        </div>
    );
};

export default RPA;
