
import React from 'react';
import AppBarComponent from './components/appBar';
import { Box, Container, Typography, Grid } from '@mui/material';
import Footer from './components/footer';
import './digitalExperiences.css'; // Import the CSS file
import ContactButton from '../../components/contact-button';

const Hero = () => {
  return (
    <Box className="hero-box">
      <img
        src="/images/customer-centric.jpg"
        alt="About Us"
        className="hero-image"
      />

      <Grid container spacing={4} alignItems="center" className="hero-grid">
        <Grid item xs={12} md={6}></Grid> {/* Empty left column */}
        <Grid item xs={12} md={6} className="hero-text-grid">
          <Typography variant="h4" gutterBottom className="hero-typography">
            We build data-driven platforms around your
            clients, enabling omnichannel experiences
          </Typography>
          <ContactButton text="Start building 360 customer views" mobile={false} backgroundWhite={false} />
        </Grid>
      </Grid>
    </Box>
  );
};

const TextSection = () => {
  return (
    <Box className="text-section-box">
      <Container className="text-section-container">
        <Typography className="text-section-title" >
          From high performing backend systems to cutting-edge front end designs, get the toolkit for digital success
        </Typography>
      </Container>
      <Box py={5} />
      <Container maxWidth="lg">
        <Box className="text-section-content">
          <Box className="text-section-row">
            <Typography variant="body1" color="var(--primary-blue)" gutterBottom className="text-section-subtitle">
              Custom software for personalized experiences
            </Typography>
            <Typography variant="body" className="text-section-body">
              Delight customers with highly personalized experiences enabled by real time data, beautifully displayed based on their preferences
            </Typography>
          </Box>

          <Box className="text-section-row">
            <Typography variant="body1" color="var(--primary-blue)" gutterBottom className="text-section-subtitle">
              Proprietary software for cutting edge innovation
            </Typography>
            <Typography variant="body" className="text-section-body">
              Create the software you need to enable your innovative business models and deliver value on new channels
            </Typography>
          </Box>

          <Box className="text-section-row">
            <Typography variant="body1" color="var(--primary-blue)" gutterBottom className="text-section-subtitle">
              Cloud platforms with zero downtime
            </Typography>
            <Typography variant="body" className="text-section-body">
              Make critical data always available with the enhanced reliability of cross-regional cloud based disaster recovery and high availability
            </Typography>
          </Box>

          <Box className="text-section-row">
            <Typography variant="body1" color="var(--primary-blue)" gutterBottom className="text-section-subtitle">
              Mobile applications for cross-platform competitiveness
            </Typography>
            <Typography variant="body" className="text-section-body">
              Create a seamless cross-platform experience for your customers with a behaviour-engineered mobile application
            </Typography>
          </Box>
        </Box>
      </Container>

      <Box py={5} />
    </Box>
  );
};

const DigitalExperiences = () => {
  return (
    <div>
      <AppBarComponent />

      <Hero />
      <TextSection />

      <Footer />
    </div>
  );
};

export default DigitalExperiences;
