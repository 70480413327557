import React, {useState} from 'react';
import { Box, Typography, TextField, Grid, Container } from '@mui/material';
import AppBarComponent from './components/appBar'; // Assuming you have your AppBar component
import Footer from './components/footer';
import './contact.css'; // Import the separated CSS file
const ContactPageSection = () => {
    // State to track form values
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: '',
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission

        // Prepare your API request
        fetch('https://e-mobile-api-ituma2afya-oa.a.run.app/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Send the form data
        })
        .then(response => {
            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.error('Form submission failed');
            }
        })
        .catch(error => {
            console.error('Error submitting form:', error);
        });
    };

    return (
        <Box className="contact-page-section">
            {/* Full-width Image */}
            <img
                src="/images/contact.jpg" // Replace with your image path
                alt="Contact Us Banner"
                className="contact-banner-image"
            />
            <Container className="contact-header-container">
                <Typography variant="h3" className="contact-header-text">
                    Make your data work for you - book a meeting<br /> now
                </Typography>
            </Container>

            <Box className="spacer" />
            <Container maxWidth="xl" className="contact-container">
                <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                        <Box className="contact-form-container">
                            <Typography variant="body" className="contact-form-title">
                                Contact us
                            </Typography>
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body" className="input-label">
                                            Full Name *
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            required
                                            name="fullName" // Add name attribute
                                            value={formData.fullName} // Set value from state
                                            onChange={handleChange} // Handle input changes
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'input-field',
                                            }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body" className="input-label">
                                            Email *
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            required
                                            name="email" // Add name attribute
                                            value={formData.email} // Set value from state
                                            onChange={handleChange} // Handle input changes
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'input-field',
                                            }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body" className="input-label">
                                            Write a Message
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            multiline
                                            rows={3}
                                            name="message" // Add name attribute
                                            value={formData.message} // Set value from state
                                            onChange={handleChange} // Handle input changes
                                            InputProps={{
                                                disableUnderline: true,
                                                className: 'input-field',
                                            }}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>

                                <Box className="submit-button-container">
                                    <button
                                        type="submit"
                                        className="send-button"
                                    >Submit</button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" className="contact-info">
                            DATAMORPHOSIS SRL<br /><br />
                            contact@data-morphosis.net<br /><br />
                            Soseaua Oltenitei 188<br /><br />
                            Bucharest, Romania
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
const ContactPage = () => {
    return (
        <div>
            <AppBarComponent />
            <ContactPageSection />
            <Footer />
        </div>
    );
};

export default ContactPage;
