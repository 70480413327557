import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigationPage from './navigation-page'; // Import the NavigationPage component
import "./appBar-mobile.css"

const AppBarMobile = () => {
    const [showNavigationPage, setShowNavigationPage] = useState(false);
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/Home'); // Navigate to the Home page
    };

    const toggleNavigationPage = () => {
        setShowNavigationPage(!showNavigationPage);
    };

    return (
        <>
            <Box className="app-bar-mobile">
                <Box className="logo-container-mobile" onClick={navigateToHome}>
                    <img src="/images/logo.jpg" alt="Logo" className="logo-mobile" />
                    
                </Box>
                <p className='appbar-title'>
                        data/morphosis
                    </p>
                <IconButton edge="end" onClick={toggleNavigationPage}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 40 40"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="hamburger-icon-mobile"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 6h18M3 12h18M3 18h18" />
                    </svg>



                </IconButton>
            </Box>
            {showNavigationPage && <NavigationPage closeNavigationPage={toggleNavigationPage} />}
        </>
    );
};

export default AppBarMobile;
