import React from 'react';
import Lottie from 'lottie-react';
import animationData1 from '../../../animations/subscripitons 2.json';
import animationData2 from '../../../animations/subscriptions 3.json';
import animationData3 from '../../../animations/subscriptions 4.json';
import animationData4 from '../../../animations/animation3.json';
import './animation-mobile.css';

const AnimatedComponentMobile = ({ animationType, topText, bottomText, paragraphs, buttonText, buttonUrl }) => {
  const getAnimationData = (type) => {
    switch (type) {
      case 'animation1':
        return animationData1;
      case 'animation2':
        return animationData2;
      case 'animation3':
        return animationData3;
      case 'animation4':
        return animationData4;
      default:
        return null;
    }
  };

  return (
    <div className="animated-container-mob">
      {/* Lottie Animation */}
      <div className="lottie-container">
        <Lottie animationData={getAnimationData(animationType)} loop={true} style={{ height: '200px', marginBottom: '10px' }} />
      </div>

      {/* Rectangle with dash and text */}
      <div className="rectangle">
        <div className="text-above">{topText}</div>
        <div className="dash"></div>
        <div className="text-below">{bottomText}</div>
      </div>
      


      <div className="paragraphs-mob">
      {paragraphs.map((paragraph, index) => {
          // Define the classes conditionally based on the index and the matching condition
          let paragraphClass = '';

          if (index === 0) {
            // First row gets a special class
            paragraphClass = 'first-row-mob';
          } else if (index === 1) {
            // Second row gets a different class
            paragraphClass = 'second-row-mob';
          } else {
            // All others get a default class
            paragraphClass = 'default-mob';
            // If paragraph matches the set condition, apply the second-row class instead
            if (paragraph === 'SET_VALUE') {
              paragraphClass = 'second-row-mob';
            }
          }return (
            <p key={index} className={paragraphClass}>
              {paragraph}
            </p>
          );
        })}

          {/* Button and URL right after the text */}
          <button className="hover-button-mob">{buttonText}</button><br />
          <a href={buttonUrl} className="hover-url-mob" target="_blank" rel="noopener noreferrer">
            {buttonUrl}
          </a>
        </div>

    </div>
  );
};

export default AnimatedComponentMobile;
