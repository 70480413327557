import React, { useState } from 'react';
import Lottie from 'lottie-react';
import animationData1 from '../../../animations/subscripitons 2.json';
import animationData2 from '../../../animations/subscriptions 3.json';
import animationData3 from '../../../animations/subscriptions 4.json';
import animationData4 from '../../../animations/animation3.json';
import './animation.css';

const AnimatedComponent = ({ animationType, topText, bottomText, paragraphs, buttonText, buttonUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const getAnimationData = (type) => {
    switch (type) {
      case 'animation1':
        return animationData1;
      case 'animation2':
        return animationData2;
      case 'animation3':
        return animationData3;
      case 'animation4':
        return animationData4;
      default:
        return null; // or return a default animation
    }
  };

  return (
    <div
      className={`animated-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Lottie Animation */}
      <div className={`lottie-container .${isHovered ? 'clipped' : ''}`}>
        <Lottie animationData={getAnimationData(animationType)} loop={true} style={{ height: '200px', width: '200px', marginBottom: '10px' }} />
      </div>

      {/* Rectangle with dash and text */}
      <div className="rectangle">
        <div className="text-above">{topText}</div>
        <div className="dash"></div>
        <div className="text-below">{bottomText}</div>

      </div>
      <div className={`paragraphs ${isHovered ? 'float-up' : ''}`}>
        {paragraphs.map((paragraph, index) => {
          // Define the classes conditionally based on the index and the matching condition
          let paragraphClass = '';

          if (index === 0) {
            // First row gets a special class
            paragraphClass = 'first-row-class';
          } else if (index === 1) {
            // Second row gets a different class
            paragraphClass = 'second-row-class';
          } else {
            // All others get a default class
            paragraphClass = 'default-class';
            // If paragraph matches the set condition, apply the second-row class instead
            if (paragraph === 'SET_VALUE') {
              paragraphClass = 'second-row-class';
            }
          }

          return (
            <p key={index} className={paragraphClass}>
              {paragraph}
            </p>
          );
        })}

        {/* Button and URL appear on hover */}
        {isHovered && (
          <div className="hover-content">
            <button
              className="hover-button"
              onClick={() => window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth' // smooth scrolling animation
              })}
            >
              {buttonText}
            </button>
            <br />
            <a
              href={buttonUrl}
              className="hover-url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonUrl}
            </a>
          </div>
        )}

      </div>

    </div>
  );
};

export default AnimatedComponent;
