import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './footer.css';  // Import the external CSS file

const Footer = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-content">
        <Typography variant="h6">
          data/morphosis
        </Typography>

        <Box className="social-icons">
          <a href="https://www.linkedin.com/company/your-company" target="_blank" rel="noopener noreferrer">
            <img src="images/linkedin.png" alt="LinkedIn" className="social-icon" />
          </a>
          <a href="https://www.youtube.com/channel/your-channel" target="_blank" rel="noopener noreferrer">
            <img src="/images/youtube.png" alt="YouTube" className="social-icon" />
          </a>
          <a href="https://twitter.com/your-handle" target="_blank" rel="noopener noreferrer">
            <img src="/images/facebook.png" alt="Facebook" className="social-icon" />
          </a>
        </Box>

        <Typography variant="body2">
          2023 data/morphosis - All rights reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
