import React, {useState} from 'react';
import { Box, Typography, TextField, Container } from '@mui/material';
import AppBarComponent from './components/appBar-mobile'; // Assuming you have your AppBar component
import Footer from '../desktop/components/footer';
import './contact.css'; // Import the separated CSS file

const ContactPageSection = () => {
   // State to track form values
   const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
});

// Handle input change
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
};

// Handle form submission
const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Prepare your API request
    fetch('https://e-mobile-api-ituma2afya-oa.a.run.app/contact', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the form data
    })
    .then(response => {
        if (response.ok) {
            console.log('Form submitted successfully');
        } else {
            console.error('Form submission failed');
        }
    })
    .catch(error => {
        console.error('Error submitting form:', error);
    });
};

return (
    <Box className="contact-page-section-mobile">
        {/* Scaled down image */}
        <img
            src="/images/contact.jpg" // Replace with your image path
            alt="Contact Us Banner"
            className="contact-banner-image-mobile"
        />
        <Container className="contact-header-container-mobile">
            <Typography variant="h3" className="contact-header-text-mobile">
                Make your data work for you - book a meeting<br /> now
            </Typography>
        </Container>

        <Box className="spacer-mobile" />
        <Container maxWidth="xl" className="contact-container-mobile">
            {/* All components in a column */}
            <Box className="contact-form-container-mobile">
                <Typography variant="body" className="contact-form-title-mobile">
                    Contact us
                </Typography>
                <Box className="spacer-mobile" />
                <form className="contact-form-mobile" onSubmit={handleSubmit}>
                    <Box>
                        <Typography variant="body" className="input-label-mobile">
                            Full Name *
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            required
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange} // Handle input changes
                            InputProps={{
                                disableUnderline: true,
                                className: 'input-field-mobile',
                            }}
                            margin="normal"
                        />
                    </Box>
                    <Box>
                        <Typography variant="body" className="input-label-mobile">
                            Email *
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            required
                            name="email"
                            value={formData.email}
                            onChange={handleChange} // Handle input changes
                            InputProps={{
                                disableUnderline: true,
                                className: 'input-field-mobile',
                            }}
                            margin="normal"
                        />
                    </Box>
                    <Box>
                        <Typography variant="body" className="input-label-mobile">
                            Write a Message
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            multiline
                            rows={3}
                            name="message"
                            value={formData.message}
                            onChange={handleChange} // Handle input changes
                            InputProps={{
                                disableUnderline: true,
                                className: 'input-field-mobile',
                            }}
                            margin="normal"
                        />
                    </Box>

                    <Box className="submit-button-container-mobile">
                        <button
                            type="submit"
                            className="send-button-mobile"
                        >
                            Submit
                        </button>
                    </Box>
                </form>
            </Box>
                <Typography variant="h6" className="contact-info-mobile">
                    DATAMORPHOSIS SRL<br /><br />
                    contact@data-morphosis.net<br /><br />
                    Soseaua Oltenitei 188<br /><br />
                    Bucharest, Romania
                </Typography>
            </Container>

            <Box className="spacer-mobile" />
        </Box>
    );
};

const ContactMobile = () => {
    return (
        <div>
            <AppBarComponent />
            <ContactPageSection />
            <Footer />
        </div>
    );
};

export default ContactMobile;
