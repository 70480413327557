import React from 'react';
import AppBarComponent from './components/appBar';
import { Box, Container, Typography, Grid } from '@mui/material';
import Footer from './components/footer';
import './about.css'; // Import the CSS file
import ContactButton from '../../components/contact-button';

const TextSpansSection = () => {
  return (
    <Box className="text-spans-section">
        <Typography variant="h3" className="primary-blue2 text-center">
          Transform your digital landscape faster than ever with our Mule certified consultants
        </Typography>

      {/* Image */}
      <Container maxWidth="md">
        <img src="/images/about3.jpg" alt="Section" className="responsive-image1" />
      </Container>

      <Container className="text-center my-4">
        <Typography variant="h4" className="primary-blue1">
          Principles we employ to add value
        </Typography>
      </Container>

      <Grid container spacing={2} className="text-spans-columns">
        <Grid item xs={12} sm={6}>
          <Typography variant="body" paragraph className="text-left px-25">
            Reusability and scalability - unlock assets at business level, decentralize access, transform data, and create reusable assets that reduce costs, development time, and improve customer satisfaction.
          </Typography>

          <Box className="spacer px-25" />

          <Typography variant="body" paragraph className="text-left px-25">
            High performance and flexibility - We leverage Mule’s powerful event-driven architecture engine to move millions of records between applications and deliver data in ready-to-use formats, all in real time.
          </Typography>

          <Box className="spacer" />

          <Typography variant="body" paragraph className="text-left px-25">
            Out-of-the-box connectors - Using Mulesoft's prebuilt connectors, we enable companies like yours to create integrated business solutions over hundreds of systems 5x faster.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className="spacer" />

          <Typography variant="body" paragraph className="text-right px-25">
            Agile & the template-driven approach - Mulesoft APIs enable 27% faster automation. We use Anypoint Exchange's template-based approach to accelerate development even further and provide a healthy foundation.
          </Typography>

          <Box className="spacer" />

          <Typography variant="body" paragraph className="text-right px-25">
            Security & contained environments - From single applications to entire domains, we secure Mulesoft application networks using policies in a highly flexible way that gives you total control.
          </Typography>

          <Box className="spacer" />
        </Grid>
      </Grid>
    </Box>
  );
};

const AboutHero = () => {
  return (
    <Box className="about-hero">
      <img src="/images/about1.jpg" alt="About Us" className="responsive-image" />

      <Grid container spacing={4} alignItems="center" className="hero-text-container">
        <Grid item xs={12} md={6} className="px-4 vertical-margin">
          <Typography  className="primary-blue text-center">
            System integration & business process automation with full lifecycle support offered by certified experts.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}></Grid> {/* Empty right column */}
      </Grid>
    </Box>
  );
};

const TechnologySection = () => {
  return (
    <Box className="technology-section">
        <Typography className="primary-blue text-center">
          Your trusted technology partner
        </Typography>

      <Container maxWidth="md">
        <Box className="tech-description">
          <Typography className='para' variant='body' paragraph>
            data/morphosis is a team of certified experts in process automation, system integration, and overall digital landscape transformation. From solution design to deployment and maintenance, we combine tech stacks including Flutter mobile development, React-based platforms, Python integration, and Mulesoft implementation to deliver relevant insights based on real-time data to power complete business solutions for your digital space.
          </Typography>
        </Box>
      </Container>

        <Typography className="primary-blue1 text-center">
          Decentralize access to organizational data across <br />systems and tech stacks
        </Typography>

      <Box className="relative-container">
        <img src="/images/about2.jpg" alt="Section" className="responsive-image" />
        <div className='centered-button'>
        <ContactButton
          text="Set up your team now" backgroundWhite={false} mobile={false}/>
          </div>
      </Box>
    </Box>
  );
};

const About = () => {
  return (
    <div>
      <AppBarComponent />
      <AboutHero />
      <TechnologySection />
      <TextSpansSection />
      <Footer />
    </div>
  );
};

export default About;
