import React from 'react';
import { Box, } from '@mui/material';
import './about.css';
import AppBarMobile from './components/appBar-mobile';
import Footer from '../desktop/components/footer';
import ContactButton from '../../components/contact-button';

const AboutPage = () => {
    return (
        <div>
        <AppBarMobile/>
        <Box className="about-page-mobile">
            <img src="/images/about1.jpg" alt="About Us" className="about-image-top-mobile" />
            <p  className="about-title-mobile">
            System integration & business process automation with full lifecycle support offered by certified experts
            </p>
            <br/>            
            <ContactButton text="Set up your team now" mobile={false} backgroundWhite={false}/>
            <br/>
            <p  className="about-header-mobile">Your trusted technology partner</p>
            <br/>
            <p className="about-paragraph-mobile">
            data/morphosis is a team of certified experts in process automation, system integration and overall digital landscape transformation. From solution design to deployment and maintenance, we combine tech stacks including Flutter mobile development, React based platforms, Python integration and Mulesoft implementation to deliver relevant insights based on real-time data to power complete business solutions for your digital space.
            </p>
            <br/>
            <p  className="about-header-mobile">
            Decentralize access to organizational data across systems and tech stacks
            </p>
            <br/>
            <img src="/images/about2.jpg" alt="Our Values" className="about-image-values-mobile" />
            <br/>
            <p  className="about-header-mobile">
                Transform your digital landscape faster than ever with our Mule certified consultants
            </p>
            <br/>
            <img src="/images/about3.jpg" alt="Our Team" className="about-image-team-mobile" />
            <br/>
            <p  className="about-subtitle-mobile">
            Principles we employ to add value
            </p>
            <p className="about-paragraph-mobile">
            Reusability and scalability - unlock assets at business level, decentralize access, transform data and create reusable assets that reduce costs, development time and improve customer satisfaction
            </p>
            <p className="about-paragraph-mobile">
            Agile & the template driven approach - Mulesoft APIs enable 27% faster automation. We use Anypoint Exchange's template based approach to accelerate development even further and provide a healthy foundation
            </p>
            <p className="about-paragraph-mobile">
            High performance and flexibility - We leverage Mule’s powerful event driven architecture engine to move millions of records between applications and deliver data in ready to use formats using built-in capabilities, all in real time
            </p>
            <p className="about-paragraph-mobile">
            Security & contained environments - From single applications to entire domains, we secure Mulesoft application networks using policies in a highly flexible way that gives you total control
            </p>
            <p className="about-paragraph-mobile">
            Out of the box connectors - Using Mulesoft's prebuilt connectors we enable companies like yours to create integrated business solutions over hundreds of systems 5x faster
            </p>
        </Box>
        <Footer/>
        </div>
    );
};

export default AboutPage;
