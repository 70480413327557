import React, { useEffect, useState, useRef } from 'react';
import AppBarComponent from './components/appBar';
import { Box } from '@mui/material';
import Footer from './components/footer';
import './apiPoweredDT.css'; // Import the CSS file
import ContactButton from '../../components/contact-button';

const ImagesSection = () => {
  return (
    <div className="images-section-dt" >
      <div className="row-content-dt">
        <div className="column-dt image-column-dt">
          <img src="/images/dt-image-small-top.png" alt="Left Content" className="image-left-dt" />
        </div>
        <div className="column-dt text-column-dt">
          <p className="text-paragraph-dt">
            If your data is siloed or unstructured and only available in a format that requires further processing to become actionable, you can be losing up to $15M per year and tripling the time required to make strategic decisions
          </p>
        </div>
      </div>
      <div className="spacer-dt" style={{ height: '10vh' }} />
      <div className="blue-title-container-dt">
        <p className="blue-title-dt">Personalized & connected</p>
        <p className="text-right-dt">Create new experiences</p>
      </div>
      <div className="image-center-dt">
        <img src="/images/dt-image-mid.png" alt="Center Image" className="image-center-dt" />
        <p className="overlay-text-dt">
          The average organization uses 88 applications.
          <br /><br />
          Attempting to deliver a unified customer experience when business processes require employees to jump from system to system to access or manually duplicate the data they need is impossible.
          <br /><br />
          Quickly and easily connect various applications, servers or platforms using a scalable strategy that is built for a changing landscape with data/morphosis teams.
        </p>
      </div>
    </div>
  );
};
const contentData = [
  {
    imgSrc: '/images/dt-image-1.png',
    textColumn2: 'Error prone manual processes',
    textColumn3: 'Highly efficient automated end to end processes',
  },
  {
    imgSrc: '/images/dt-image-2.png',
    textColumn2: 'Point to point connections',
    textColumn3: 'Decentralized access for multiple consumers',
  },
  {
    imgSrc: '/images/dt-image-3.png',
    textColumn2: 'Unavailability of relevant data in real time',
    textColumn3: 'Formatted data on demand',
  },
];
const AnimationSection = () => {
  const [scrollY, setScrollY] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(Array(contentData.length).fill(false)); // Track animation state
  const sectionRef = useRef(null);
  const iconRefs = useRef([]);

  // Function to update the scroll position
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkAnimation = () => {
      iconRefs.current.forEach((iconRef, index) => {
        if (iconRef) {
          const iconPosition = iconRef.getBoundingClientRect();
          const isVisible = iconPosition.top < window.innerHeight && iconPosition.bottom > 0;

          if (isVisible && !hasAnimated[index]) {
            setHasAnimated((prev) => {
              const updated = [...prev];
              updated[index] = true; // Mark this icon as animated
              return updated;
            });
          }
        }
      });
    };

    checkAnimation();
    // Trigger the animation check on scroll
    window.addEventListener('scroll', checkAnimation);

    return () => {
      window.removeEventListener('scroll', checkAnimation);
    };
  }, [hasAnimated]); // Re-run when animation state changes

  return (
    <div className="animation-section-dt" ref={sectionRef}>
      <p className="blue-title-dt-ani">
        Start breaking down the barriers between disparate systems and establish smooth data flows that enable operational excellence
      </p>
      <div className="animation-columns-dt">
        {contentData.map((item, rowIndex) => {
          const iconRef = (el) => (iconRefs.current[rowIndex] = el);
          const iconPosition = iconRefs.current[rowIndex]?.getBoundingClientRect();
          const isVisible = iconPosition && iconPosition.top < window.innerHeight && iconPosition.bottom > 0;

          // Control movement based on visibility and whether it has already animated
          const translateXValue = hasAnimated[rowIndex] 
            ? 0 // Keep in place after animation
            : isVisible
              ? Math.max(0, 150 - (scrollY - iconPosition.top) / 5)  // Move based on scrollY
              : 150; // Start off-screen

          // Determine the opacity of the overlay based on the icon position
          let opacityValue = 0;

          if (iconPosition) {
            const centerY = window.innerHeight / 7;
            const iconTop = iconPosition.top;

            // Check if the icon is above the center line
            if (iconTop < centerY) {
              const distanceFromCenter = centerY - iconTop;
              opacityValue = Math.min(distanceFromCenter / (centerY / 2), 1); // Clamp between 0 and 1
              opacityValue = opacityValue * 0.6 + 0.2; // Scale to 20% - 80%
            }
          }

          console.log(`Row ${rowIndex}: Opacity Value = ${opacityValue}`); // Debugging log

          return (
            <div className="animation-row-dt" key={rowIndex} style={{ position: 'relative' }}>
              <div className="animation-column-dt" ref={iconRef}>
                <img
                  src={item.imgSrc}
                  alt={`Image ${rowIndex + 1}`}
                  className="scroll-image-dt"
                  style={{ transform: `translateX(-${translateXValue}%)` }}
                />
                <div className="overlay" style={{ opacity: opacityValue }} />
              </div>
              <div className="animation-column-dt background-grey-dt">
                <p className="text-black-dt">{item.textColumn2}</p>
              </div>
              <div className="animation-column-dt background-blue-dt">
                <p className="text-white-dt">{item.textColumn3}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const TextSection1 = () => {
  return (
    <div className="text-section1-dt">
      <h2 className="blue-title-dt-ani1">Our teams use best practices to ensure a healthy foundation for your API network to grow on</h2>
      <p className="paragraph-dt-spaced">Automation is the frontier to digital transformation - leading to exponentially greater benefits and becoming crucial for competitive advantage</p>
      <p className="paragraph-dt-spaced">Unlock real-time, decentralized access to organizational data now, without relying on easily breakable, tightly coupled connections to legacy systems that can pose significant problems for digital transformation.</p>
      <h2 className="blue-title-dt-ani">Make a decisive step in democratizing and decentralizing access to create and foster a thriving culture</h2>
      <h3 className="subtitle-dt">Design, build and deploy API based integrations faster than ever with data/morphosis and Mulesoft's Anypoint Platform</h3>
      <div className="paragraph-dt line-spacing-dt">
        <p className='custom-spacing'>Highly adaptable to any project, we have tailored Mulesoft to help our clients:</p>
        <ul className='custom-spacing'>
          <li>Create a fresh, relevant customer experience delivered through a 360 customer view featuring real-time, relevant data-driven insights</li>
          <li>Integrate global solutions into local landscapes</li>
          <li>Ensure synchronization and orchestrate coordination across departments and their systems</li>
          <li>Integrate new sales channels and payment solutions into existing solutions</li>
          <li>Re-engineer and migrate legacy integrations</li>
        </ul>
      </div>

    </div>
  );
};
const TextSection2 = () => {
  return (
    <div className="text-section2-dt">
      <div className="blue-background-dt">
        <h2 className="white-text-dt">
          Automate your business processes for higher productivity and save up to 40%
        </h2>
      </div>
      <h2 className="centered-title-dt">The tools we use to transform businesses</h2>
      <div className="grid-text-dt">
        <div className="grid-item-dt" style={{ gridArea: 'item1' }}>
          <p className="text-black-dt">
            Mulesoft's <strong className="blue-bold-dt">Anypoint Platform </strong> is fully integrated into Salesforce Flow, a complete suite of automation technologies across the Customer 360 platform that saves customers more than 109 billion hours or $2.19 trillion in business value!
          </p>
        </div>
        <div className="grid-item-dt" style={{ gridArea: 'item2' }}>
          <p className="text-black-dt">
            The #1 platform for APIs and integrations provides an all-in-one solution for API management, development, documentation, deployment, monitoring and beyond - the <strong className="blue-bold-dt">Anypoint Studio</strong>.
          </p>
        </div>
        <div className="grid-item-dt" style={{ gridArea: 'item3' }}>
          <p className="text-black-dt">
            <strong className="blue-bold-dt">Mulesoft Composer </strong> enables business teams to automate simple processes, access data and IT assets through simple clicks, not code.
          </p>
        </div>
        <div className="grid-item-dt" style={{ gridArea: 'item4' }}>
          <p className="text-black-dt">
            <strong className="blue-bold-dt">Mulesoft RPA</strong> allows businesses to capture and automate workflows instantly – whether through a UI, document, or image, all with point-and-click tools.
          </p>
        </div>
      </div>
    </div>
  );
};
const TextSection3 = () => {
  return (<>
    <div className="blue-background-dt">
      <h2 className="white-text-dt">
        On average, Mulesoft APIs enable 27% faster automation of business processes, but together we are sure we can do better!
      </h2>
    </div>
    <div className='dt-row'>
    <p className="text-black-dt-center"> <strong className="blue-bold-dt-center">
      Transition your projects from concept to reality in no time with out-of-the-box connectors and real-time data previews
    </strong></p>
    <p className="text-black-dt-center">
      Easily connect to external systems
    </p>
    <p className="text-black-dt-center">
      Quickly deploy common integrations
    </p>
    </div>
    <div className='dt-row'>
    <p className="text-black-dt-center">
    <strong className="blue-bold-dt-center">
      Unlock data safely from any app and build integrations using a secure, scalable tool that provides relevant insights in real time
    </strong>
    </p>
    <p className="text-black-dt-center">
      Maintain security best practices
    </p>
    <p className="text-black-dt-center">
      Create secure triggers
    </p>
    </div>
    <div className='dt-row'>
    <p className="text-black-dt-center">
    <strong className="blue-bold-dt-center">
      Maintain visibility end ensure the security your organization needs for over every integration with the proper tools to manage, govern and monitor
    </strong>
    </p>
    <p className="text-black-dt-center">
      Maintain governance and control
    </p>
    <p className="text-black-dt-center">
      Monitor every integration effectively, without slowing down the business
    </p>
    </div>
    <div className='dt-row'>
    <p className="text-black-dt-center">
    <strong className="blue-bold-dt-center">
      Transform and modify data using a powerful editor that’s guided and easy to use
      Perform complex data processing
    </strong>
    </p>
    <p className="text-black-dt-center">
      Format data for destination systems
    </p>
    <p className="text-black-dt-center">
      Add calculations to your flows
    </p>
    </div>
  </>);
}
const Hero = () => {
  return (
    <Box className="hero-dt">
      <Box className="hero-banner-dt">
        <p className='hero-title-dt'>
          Enabling meaningful<br /> digital experiences
        </p>
        <p className='hero-subtitle-dt'>
          with integrated systems personalized <br />
          omnichannel experiences that respond to <br />
          customers' real time needs
        </p>
        <Box className="hero-button-dt">
          <ContactButton text="Start leveraging your data today" mobile={false} backgroundWhite={false} />
        </Box>
      </Box>
    </Box>
  );
};
const APIpoweredDT = () => {
  return (
    <div>
      <AppBarComponent />
      <Hero />
      <ImagesSection />
      <AnimationSection />
      <TextSection1 />
      <TextSection2 />
      <TextSection3 />
      <Footer />
    </div>
  );
};

export default APIpoweredDT;
