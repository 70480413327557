import React from 'react';
import AppBarComponent from './components/appBar-mobile'; 
import { Box, Container, Typography,  Grid } from '@mui/material';
import Footer from '../desktop/components/footer';
import './rpa.css';
import ContactButton from '../../components/contact-button';

const TextSection = () => {
    return (
        <>
        <Box className="text-section-mob-rpa">
            <Container className="normal-text-mob">
                <Typography variant="body" paragraph>
                <br />It's time to replace repetitive manual tasks and processes with simple or advanced RPA:<br /><br />
                    100% accurate workflows on any scale, much faster than a human could, for a fraction of the price.
                    <br /><br /><br />
                </Typography>
            </Container>

            <Container className="blue-text-section-mob" gutterBottom>
                <p>
                    <span className="primary-blue-rpa-mob bold-mob">30%-35% reduction in entry level roles </span>
                    and increase mid level roles.<br/>
                    <span className="bold-mob">Everest group</span>
                    <br/>
                    <br/>
                    <br/>
                </p>
                <Typography variant="body" paragraph>
                    <span className="primary-blue-rpa-mob bold-mob">Cost reduction of 35%-65% </span>
                    for onshore operations and 10-30% for offshore operations.<br />
                    <span className="bold-mob">Institute for Robotic Process Automation</span>
                    <br/>
                    <br/>
                    <br/>
                </Typography>
                <Typography variant="body" paragraph>
                    <span className="primary-blue-rpa-mob bold-mob">85% </span>
                    of a typical firm’s 900+
                    <span className="primary-blue-rpa-mob bold-mob"> processes can be automated.</span>
                    <br />
                    <span className="primary-blue-rpa-mob bold-mob">110-140m FTEs </span>
                    could be 
                    <span className="primary-blue-rpa-mob bold-mob"> replaced by 2025.</span>
                    <br />
                    <span className="bold-mob">McKinsey & Company</span>
                    <br/>
                </Typography>
            </Container>

            <Container className="subtitle-mob">
                <p>
                    Connect business applications and automate workflows instantly – UI, documents, images and beyond – using point-and-click solutions
                </p>
            </Container>
        </Box>
        <Grid container spacing={4} alignItems="center" direction="column">
            <Grid item className="image-column-mob">
                <img
                    src="/images/rpa2.jpg"
                    alt="Section Image"
                    className="image-padding-mob"
                />
            </Grid>
            <Grid item className="text-column-mob">
                <Box className="text-box-mob">
                    <p>
                        RPA can be deployed so quickly and inexpensively in almost any situation and on any platform that it will quickly go from being a differentiator to a standard practice that every organization will need simply to survive.<br /><br /><br />
                        Our RPA experts can help you scope, test and deploy solutions, help manage the people and organizational changes it will bring, as well as guide you through new considerations on governance.<br /><br /><br />
                        Other top benefits of using the MuleSoft RPA tool include:<br /><br />
                        Unlocking data from legacy systems<br />
                        Freeing up workforce by automating manual and repetitive tasks<br />
                        Full control through centrally managed and governed automated processes<br />
                        Seamless integration with MuleSoft APIs, MuleSoft Composer Processes and hundreds of systems
                    </p>
                    <Box className="button-container-mob">
                        <ContactButton text="Build your Mulesoft RPA now" mobile={false} backgroundWhite={false} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
        </>
    );
};

const Hero = () => {
    return (
        <Box className="hero-mob">
            <Box className="hero-banner-mob">
                <p className='hero-title-rpa-mob'>
                    Error prone manual processes that disrupt your business are a thing of the past with Mulesoft RPA
                </p>
                <Box className="hero-button-mob">
                    <ContactButton text="Build your Mulesoft RPA now" mobile={false} backgroundWhite={false} />
                </Box>
            </Box>
        </Box>
    );
};

const RPA = () => {
    return (
        <div>
            <AppBarComponent />
            <Hero />
            <TextSection />
            <Footer />
        </div>
    );
};

export default RPA;
