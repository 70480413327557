import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import './appBar.css';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const AppBarComponent = (props) => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const buttonName = pathSegments[0] || 'home';
    setActiveButton(buttonName);
  }, [location]);

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <HideOnScroll {...props}>
      <AppBar position="fixed" className="appbar">
        <Toolbar className="toolbar">
          <Box className="logo-container">
            <Link to="/">
              <img src="images/logo.jpg" alt="Logo" className="logo-image" />
            </Link>
            <Typography variant="h6" component="div" className="title">
              <Link to="/" className="title-link">
                data/morphosis
              </Link>
            </Typography>
          </Box>

          <Box className="nav-buttons">
            <Box>
              <Button
                component={Link}
                to="/home"
                onClick={() => handleClick('home')}
                className={activeButton === 'home' ? 'active' : ''}
              >
                Home
              </Button>
              <Button
                component={Link}
                to="/about"
                onClick={() => handleClick('about')}
                className={activeButton === 'about' ? 'active' : ''}
              >
                About
              </Button>
              {/* <Button
                component={Link}
                to="/blog"
                onClick={() => handleClick('blog')}
                className={activeButton === 'blog' ? 'active' : ''}
              >
                Blog
              </Button> */}
              <Button
                component={Link}
                to="/API-powered"
                onClick={() => handleClick('api')}
                className={activeButton === 'API-powered' ? 'active' : ''}
              >
                API powered digital transformation
              </Button>
            </Box>
            <Box>
              <Button
                component={Link}
                to="/digital-experiences"
                onClick={() => handleClick('customer')}
                className={activeButton === 'digital-experiences' ? 'active' : ''}
              >
                Customer centric digital experiences
              </Button>
              <Button
                component={Link}
                to="/rpa"
                onClick={() => handleClick('rpa')}
                className={activeButton === 'rpa' ? 'active' : ''}
              >
                High powered RPA
              </Button>
              <Button
                component={Link}
                to="/contact"
                onClick={() => handleClick('contact')}
                className={activeButton === 'contact' ? 'active' : ''}
              >
                Contact
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default AppBarComponent;
