import React, { useEffect, useState, useRef } from 'react';
import AppBarComponent from './components/appBar-mobile';
import { Box } from '@mui/material';
import Footer from '../desktop/components/footer';
import './apiPoweredDT.css'; // Import the CSS file
import ContactButton from '../../components/contact-button';
const ImagesSection = () => {
  return (
    <div className="images-section-dt-mob">
      <div className="row-content-dt-mob">
        <div className="image-top-small">
          <img src="/images/dt-image-small-top-small.png" alt="Left Content" className="image-left-dt-mob" />
        </div>
        <div className="column-dt-mob text-column-dt-mob">
          <p className="text-paragraph-dt-mob">
            If your data is siloed or unstructured and only available in a format that requires further processing to become actionable, you can be losing up to $15M per year and tripling the time required to make strategic decisions.
          </p>
        </div>
      </div>
      <div className="spacer-dt-mob" style={{ height: '10vh' }} />
      <div className="blue-title-container-dt-mob">
        <p className="blue-title-dt-mob">Personalized & connected</p>
        <p className="text-right-dt-mob">Create new experiences</p>
      </div>
      <div className="image-center-dt-mob">
        <img src="/images/dt-image-mid.png" alt="Center Image" className="image-center-dt-mob" />
        <p className="overlay-text-dt-mob">
          The average organization uses 88 applications.
          <br /><br />
          Attempting to deliver a unified customer experience when business processes require employees to jump from system to system to access or manually duplicate the data they need is impossible.
          <br /><br />
          Quickly and easily connect various applications, servers, or platforms using a scalable strategy that is built for a changing landscape with data/morphosis teams.
        </p>
      </div>
    </div>
  );
};

const contentData = [
  {
    imgSrc: '/images/dt-image-1.png',
    textColumn2: 'Error prone manual processes',
    textColumn3: 'Highly efficient automated end to end processes',
  },
  {
    imgSrc: '/images/dt-image-2.png',
    textColumn2: 'Point to point connections',
    textColumn3: 'Decentralized access for multiple consumers',
  },
  {
    imgSrc: '/images/dt-image-3.png',
    textColumn2: 'Unavailability of relevant data in real time',
    textColumn3: 'Formatted data on demand',
  },
];

const AnimationSection = () => {
  return (
    <div className="animation-section-mob">
      <p className="blue-title-dt-ani-mob">
        Start breaking down the barriers between disparate systems and establish smooth data flows that enable operational excellence
      </p>
      <div className="animation-columns-mob">
        {contentData.map((item, rowIndex) => {
          return (
            <div className="animation-column-mob" key={rowIndex}>
              <img
                src={item.imgSrc}
                alt={`Image ${rowIndex + 1}`}
                className="scroll-image-mob"
              />
              <div className="background-grey-mob">
                <p className="text-black-mob">{item.textColumn2}</p>
              </div>
              <div className="background-blue-mob">
                <p className="text-white-mob">{item.textColumn3}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const TextSection1 = () => {
  return (
    <div className="text-section1-mob">
      <h2 className="blue-title-mob">Our teams use best practices to ensure a healthy foundation for your API network to grow on</h2>
      <p className="paragraph-mob">Automation is the frontier to digital transformation - leading to exponentially greater benefits and becoming crucial for competitive advantage</p>
      <p className="paragraph-mob">Unlock real-time, decentralized access to organizational data now, without relying on easily breakable, tightly coupled connections to legacy systems that can pose significant problems for digital transformation.</p>
      <h2 className="blue-title-mob">Make a decisive step in democratizing and decentralizing access to create and foster a thriving culture</h2>
      <h3 className="subtitle-mob">Design, build and deploy API based integrations faster than ever with data/morphosis and Mulesoft's Anypoint Platform</h3>
      <div className="paragraph-mob line-spacing-mob">
        <p className='custom-spacing-mob'>Highly adaptable to any project, we have tailored Mulesoft to help our clients:</p>
        <ul className='custom-spacing-mob'>
          <li>Create a fresh, relevant customer experience delivered through a 360 customer view featuring real-time, relevant data-driven insights</li>
          <li>Integrate global solutions into local landscapes</li>
          <li>Ensure synchronization and orchestrate coordination across departments and their systems</li>
          <li>Integrate new sales channels and payment solutions into existing solutions</li>
          <li>Re-engineer and migrate legacy integrations</li>
        </ul>
      </div>
    </div>
  );
};

const TextSection2 = () => {
  return (
    <div className="text-section2-mob">
      <div className="blue-background-mob">
        <h1 className="white-text-dt-mob">
          Automate your business processes for higher productivity and save up to 40%
        </h1>
      </div>
      <h2 className="centered-title-mob">The tools we use to transform businesses</h2>
      <div className="grid-text-mob">
        <div className="grid-item-mob" style={{ gridArea: 'item1' }}>
          <p className="text-black-mob">
            Mulesoft's <strong className="blue-bold-mob">Anypoint Platform </strong> is fully integrated into Salesforce Flow, a complete suite of automation technologies across the Customer 360 platform that saves customers more than 109 billion hours or $2.19 trillion in business value!
          </p>
        </div>
        <div className="grid-item-mob" style={{ gridArea: 'item2' }}>
          <p className="text-black-mob">
            The #1 platform for APIs and integrations provides an all-in-one solution for API management, development, documentation, deployment, monitoring and beyond - the <strong className="blue-bold-mob">Anypoint Studio</strong>.
          </p>
        </div>
        <div className="grid-item-mob" style={{ gridArea: 'item3' }}>
          <p className="text-black-mob">
            <strong className="blue-bold-mob">Mulesoft Composer </strong> enables business teams to automate simple processes, access data and IT assets through simple clicks, not code.
          </p>
        </div>
        <div className="grid-item-mob" style={{ gridArea: 'item4' }}>
          <p className="text-black-mob">
            <strong className="blue-bold-mob">Mulesoft RPA</strong> allows businesses to capture and automate workflows instantly – whether through a UI, document, or image, all with point-and-click tools.
          </p>
        </div>
      </div>
    </div>
  );
};

const TextSection3 = () => {
  return (
    <>
      <div className="blue-background-mob">
        <h2 className="white-text-dt-mob">
          On average, Mulesoft APIs enable 27% faster automation of business processes, but together we are sure we can do better!
        </h2>
      </div>
      <div className='dt-column-mob'>
        <p className="text-black-center-mob"> <strong className="blue-bold-center-mob">
          Transition your projects from concept to reality in no time with out-of-the-box connectors and real-time data previews
        </strong></p>
        <p className="text-black-center-mob">
          Easily connect to external systems
          <br/>
          Quickly deploy common integrations
        </p>
      </div>
      <div className='dt-column-mob'>
        <p className="text-black-center-mob">
          <strong className="blue-bold-center-mob">
            Unlock data safely from any app and build integrations using a secure, scalable tool that provides relevant insights in real time
          </strong>
        </p>
        <p className="text-black-center-mob">
          Maintain security best practices<br/>
          Create secure triggers
        </p>
      </div>
      <div className='dt-column-mob'>
        <p className="text-black-center-mob">
          <strong className="blue-bold-center-mob">
            Maintain visibility and ensure the security your organization needs for over every integration with the proper tools to manage, govern and monitor
          </strong>
        </p>
        <p className="text-black-center-mob">
          Maintain governance and control
          <br/>
          Monitor every integration effectively, without slowing down the business
        </p>
      </div>
      <div className='dt-column-mob'>
        <p className="text-black-center-mob">
          <strong className="blue-bold-center-mob">
            Transform and modify data using a powerful editor that’s guided and easy to use
            Perform complex data processing
          </strong>
        </p>
        <p className="text-black-center-mob">
          Format data for destination systems
          <br/>
          Add calculations to your flows
        </p>
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <div className="hero-dt-mob">
      <Box className="hero-banner-dt-mob">
        <p className='hero-title-dt-mob'>
          Enabling meaningful digital experiences
        </p>
        <p className='hero-subtitle-dt-mob'>
          with integrated systems personalized
          omnichannel experiences that respond to
          customers' real-time needs
        </p>
        <Box className="hero-button-dt-mob">
          <ContactButton text="Start leveraging your data today" mobile={false} backgroundWhite={false} />
        </Box>
      </Box>
    </div>
  );
};

const APIpoweredDT = () => {
  return (
    <div>
      <AppBarComponent />
      <Hero />
      <ImagesSection />
      <AnimationSection />
      <TextSection1 />
      <TextSection2 />
      <TextSection3 />
      <Footer />
    </div>
  );
};

export default APIpoweredDT;
