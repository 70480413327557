// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MobileHome from './pages/mobile/home';
import DesktopHome from './pages/desktop/home';
import About from './pages/desktop/about';
import APIpoweredDT from './pages/desktop/apiPoweredDT';
import DigitalExperiences from './pages/desktop/digitalExperiences';
import RPA from './pages/desktop/rpa';
import ContactPage from './pages/desktop/contact';
import NavigationPage from './pages/mobile/components/navigation-page';
import AboutMobile from './pages/mobile/about';
import DigitalExperiencesMobile from './pages/mobile/digitalExperiences';
import ContactMobile from './pages/mobile/contact';
import RPAmobile from './pages/mobile/rpa';
import APIpoweredDTMobile from './pages/mobile/apiPoweredDT';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Listen for window resize to update the device type
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* Conditionally redirect to mobile or desktop based on screen size */}
        <Route
          path="/"
          element={isMobile ? <Navigate to="/mobile" /> : <Navigate to="/home" />}
        />
        <Route path="/mobile" element={<MobileHome />} />
        <Route path="/home" element={<DesktopHome />} />
        <Route path="/about" element={<About />} />
        <Route path="/about-mobile" element={<AboutMobile />} />
        <Route path="/API-powered" element={<APIpoweredDT />} />
        <Route path="/API-powered-mobile" element={<APIpoweredDTMobile />} />
        <Route path="/digital-experiences" element={<DigitalExperiences />} />
        <Route path="/digital-experiences-mobile" element={<DigitalExperiencesMobile />} />
        <Route path="/rpa" element={<RPA />} />
        <Route path="/rpa-mobile" element={<RPAmobile />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/contact-mobile" element={<ContactMobile />} />
        <Route path="/NavigationPage" element={<NavigationPage />} />
      </Routes>
    </Router>
  );
};

export default App;
