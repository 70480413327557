import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import { Container, Box, Typography, TextField } from '@mui/material';
import AnimatedComponentMobile from './components/animation-mobile';
import Footer from '../desktop/components/footer';
import AppBarMobile from './components/appBar-mobile';

const Home = () => {
  return (
    <div>
      <AppBarMobile />
      <HeroSection />
      <AboutSection />
      <WhatWeDoSection />
      <APIConnectivity />
      <Subscriptions />
      <ContactFormSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  const bannerRef = useRef(null);

  useEffect(() => {
    if (bannerRef.current) {
      bannerRef.current.style.transform = 'translateY(0)'; // Start the banner above
    }

  }, []);
  const handleClick = () => {
    window.location.href = '/mobile#contact-section';
  };
  return (
    <div className="hero-container-home-mob">
      <Container ref={bannerRef} className="text-container-home-mob">
        <h3 className="title-home-mob">Enable meaningful digital experiences</h3>
        <p className="paragrap-home-mob">with personalized omnichannel experiences that respond to customers' real time needs</p>
      </Container>

      <div className="bottom-content-mobile">
        <p className="white-text-mobile">360 digitalization services designed to infuse innovative technologies and integrate cutting edge tools into your business</p>
        <button className="white-button-mob" onClick={handleClick}>
          Start now
        </button>
      </div>
    </div>
  );
};

const AboutSection = () => {
  return (
    <section className="about-mob">
      <h2>
        Don't let the competition get ahead - we are here <br />
        to help your organization get ahead in the digital <br />
        transformation race
      </h2>
      <div className="about-column-mob">
        <div className="about-icon-mob">
          <img src="images/mission.jpg" alt="Mission Icon" className="about-image-mob" />
        </div>
        <h3>Mission</h3>
        <p>
          We are here to ensure your business processes are properly documented, planned, and automated to avoid significant value loss over time and provide ROI on RPA, system integration, and overall digital transformation efforts.
        </p>
        <div className="about-icon-mob">
          <img src="images/value.jpg" alt="Value Icon" className="about-image-mob" />
        </div>
        <h3>Value proposition</h3>
        <p>
          Let our team's years of experience with the Mulesoft ecosystem accelerate your company's journey to organizational goals and beyond. data/morphosis was conceived to tailor its services to best suit our client's needs, and we are ready to tackle your next challenge, be it through a monthly subscription or a custom dedicated team.
        </p>
        <div className="about-icon-mob">
          <img src="images/team.jpg" alt="Team Icon" className="about-image-mob" />
        </div>
        <h3>Team</h3>
        <p>
          Our certified experts are highly skilled in system integration and automation and know all the best practices and follow the latest frameworks to create highly reusable assets that generate exponential business value.
        </p>
      </div>
    </section>
  );
};


const WhatWeDoSection = () => {
  return (
    <Box className="what-we-do-container">
      <Container maxWidth="md" className="what-we-do-content">
        <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
          What we do
        </Typography>
        <br/>
        <Typography variant="body" paragraph className="section-paragraph">
          We offer a wide range of custom software development services to help you achieve your digital transformation goals:<br /><br />
          </Typography>
          <Typography variant="body" paragraph className="section-paragraph">
          <b>Business process automation (BPA)</b> - Identify and automate your most time-consuming and error-prone processes<br /><br />
          </Typography>
          <Typography variant="body" paragraph className="section-paragraph">
          <b>System integration</b> - Integrate your on-premises applications with cloud-based applications and data sources to capture and integrate real-time data and gain real-time insights using technologies such as Mulesoft, Python, Apogee, Boomi and more<br /><br />
          </Typography>
          <Typography variant="body" paragraph className="section-paragraph">
          <b>Digital product development</b> - Design and develop and deploy custom applications for any platform, from mobile applications to enterprise business solutions.<br /><br />
          </Typography>
          <Typography variant="body" paragraph className="section-paragraph">
          <b>Digital landscape transformations</b> - Bridge the gap between your as-is and to-be landscapes from plan to production<br /><br />
        </Typography>

        <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
          What you get
        </Typography>
        <br/>
        <Typography variant="body" paragraph className="section-paragraph">
          We help businesses automate their processes and connect various applications and data sources, leading to:<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          <b>Improved efficiency and productivity</b> through <b>automated repetitive tasks</b>, freeing up your resources to focus on more strategic work.<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          <b>Enhanced customer experience</b> provided via <b>seamlessly integrated touchpoints and channels</b>, keeping your clients happy and coming back for more.<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          <b>Data-driven decisions</b> unlocked by <b>real-time data and valuable analytic insights</b> that can help you improve your operations and make better decisions.<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          <b>Boosted agility and scalability</b> with <b>automated processes synched across systems</b> that become more agile and responsive to change.​<br /><br />
        </Typography>

        <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
          Why work with data/morphisis?
        </Typography>
        <br />
        <Typography variant="body" paragraph className="section-paragraph">
          We are a team of experienced and certified software developers who are passionate about helping businesses achieve their digital transformation goals and committed to providing our clients with the highest quality of service.<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          We offer flexible engagement models to fit your budget and project needs.<br /><br />
        </Typography>
        <Typography variant="body" paragraph className="section-paragraph">
          Contact us today to learn more about how Data/Morphisis can help you digitally transform your business.
        </Typography>
      </Container>
    </Box>
  );
};

const APIConnectivity = () => {
  return (
    <section className="next-section">
      {/* Blue background span with white text */}
      <div className="blue-span-mob">
        <p className="white-text-mob">On average, Mulesoft APIs enable 27% faster automation of business processes, but together we are sure we can do better!</p>
      </div>

      {/* Blue text on white background */}
      <div className="white-background-mob">
        <p className="blue-text-mob">Learn how API led connectivity and data/morphosis can transform your organization</p>
      </div>

      {/* Three circles in a column */}
      <div className="circle-container-mob">
        <div className="circle-mob">40% cost<br />reduction</div>
        <div className="circle-mob">Complete<br />control over<br />data & access</div>
        <div className="circle-mob">27% faster<br />digitalization</div>
      </div>
    </section>
  );
};
const Subscriptions = () => {
  const firstRow = [
    {
      animationType: 'animation1',
      topText: '6.300 EUR',
      bottomText: 'month',
      paragraphs: ['Basic', '1 request at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
      buttonText: 'Subscribe now',
      buttonUrl: 'View More',
    },
    {
      animationType: 'animation2',
      topText: '8.900 EUR',
      bottomText: 'month',
      paragraphs: ['Premium', '2 requests at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
      buttonText: 'Subscribe now',
      buttonUrl: 'View More',
    },
    {
      animationType: 'animation3',
      topText: '12.600 EUR',
      bottomText: 'month',
      paragraphs: ['Premium+', 'Maintenance & support', '2 requests at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
      buttonText: 'Subscribe now',
      buttonUrl: 'View More',
    },
  ];

  const secondRow = [
    {
      animationType: 'animation4',
      topText: '7.500 EUR',
      bottomText: 'one time purchase',
      paragraphs: ['One Hit Wonder', '1 single request, no commitments', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
      buttonText: 'Subscribe now',
      buttonUrl: 'View More',
    },
  ];

  return (
    <div className='api-connectivity'>
      <p className="api-title">
        Monthly system<br />integration subscriptions
      </p>

      {/* First group of plans */}
      <div className="animated-column">
        {firstRow.map((data, index) => (
          <AnimatedComponentMobile key={index} {...data} />
        ))}
      </div>

      <div className="scalable-container-mob">
        <p className="scalable-container-mob-p">
          Free templates to help you manage your integration projects with every plan
        </p>
      </div>

      {/* Second group of plans */}
      <div className="animated-column">
        {secondRow.map((data, index) => (
          <AnimatedComponentMobile key={index} {...data} />
        ))}
      </div>

      <p className="api-title">
        One time interventions to automate your business processes
      </p>

    </div>
  );
}

const ContactFormSection = () => {
  // State to track form values
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
    details: '',
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Prepare your API request
    fetch('https://e-mobile-api-ituma2afya-oa.a.run.app/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData), // Send the form data
    })
    .then(response => {
      if (response.ok) {
        console.log('Form submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    });
  };

  return (
    <Box id="contact-section" className="contact-form-section-mobile">
    
      <Box className="form-container-mobile">
        <Container maxWidth='md' className="form-content-mobile">
        <a href="#contact-section">
          <p className="form-title-mobile">
            Get a Personalized Offer
          </p>
          </a>
          <form className="form-fields-mobile" onSubmit={handleSubmit}>
            <Box className="form-field-mobile">
              <Typography variant="body1" color="white" className="form-label-mobile">
                Full Name *
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                label="e.g. John Doe"
                name="fullName" // Add name attribute
                value={formData.fullName} // Set value from state
                onChange={handleChange} // Handle input changes
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderBottom: '3px solid white',
                    color: 'white',
                  },
                }}
                className="input-field-mobile"
              />
            </Box>

            <Box className="form-field-mobile">
              <Typography variant="body1" color="white" className="form-label-mobile">
                Email *
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                label="e.g. name@example.com"
                name="email" // Add name attribute
                value={formData.email} // Set value from state
                onChange={handleChange} // Handle input changes
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderBottom: '3px solid white',
                    color: 'white',
                  },
                }}
                className="input-field-mobile"
              />
            </Box>

            <Box className="form-field-mobile">
              <Typography variant="body1" color="white" className="form-label-mobile">
                Phone
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                label="e.g. +40712 345 678"
                name="phone" // Add name attribute
                value={formData.phone} // Set value from state
                onChange={handleChange} // Handle input changes
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderBottom: '3px solid white',
                    color: 'white',
                  },
                }}
                className="input-field-mobile"
              />
            </Box>

            <Box className="form-field-mobile">
              <Typography variant="body1" color="white" className="form-label-mobile">
                Company
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                label="Company"
                name="company" // Add name attribute
                value={formData.company} // Set value from state
                onChange={handleChange} // Handle input changes
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderBottom: '3px solid white',
                    color: 'white',
                  },
                }}
                className="input-field-mobile"
              />
            </Box>

            <Box className="form-field-mobile">
              <Typography variant="body1" color="white" className="form-label-mobile">
                Give Us More Details
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                multiline
                rows={4}
                name="details" // Add name attribute
                value={formData.details} // Set value from state
                onChange={handleChange} // Handle input changes
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderBottom: '3px solid white',
                    color: 'white',
                  },
                }}
                className="input-field-mobile"
              />
            </Box>

            <button
              type="submit"
              className="send-contact-button-mob"
            >
              Request an Offer
            </button>
          </form>
        </Container>
      </Box>
    </Box>
  );
};



export default Home;
