import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Typography, TextField, Grid } from '@mui/material';
import './home.css';
import AppBarComponent from './components/appBar';
import AnimatedComponent from './components/animation';
import Footer from './components/footer';
import ContactButton from '../../components/contact-button';

const HomePage = () => {
    const contactSectionRef = useRef(null);
    useEffect(() => {
        // Scroll to the contact section if there's a hash in the URL
        if (window.location.hash === '#contact-section') {
            const timer = setTimeout(() => {
                if (contactSectionRef.current) {
                    contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0); // Slight delay to ensure the element is rendered

            return () => clearTimeout(timer);
        }
    }, []);
    return (
        <div>
            {AppBarComponent()}
            {HeroSection()}
            {AboutSection()}
            {WhatWeDoSection()}
            {APIConnectivity()}
            {AnimatedRow()}
            <Box ref={contactSectionRef}>
                <ContactFormSection />
            </Box>
            {Footer()}
        </div>
    );
};

const HeroSection = () => {
    const bannerRef = useRef(null);


    return (
        <Box className="hero-section-container">
            {/* Banner */}
            <Container ref={bannerRef} className="banner">
                <p className='banner-home-title'>
                    Enabling meaningful digital experiences
                </p>
                <p className='banner-home-p'>
                    with personalized omnichannel experiences that respond to customers' real time needs
                </p>
            </Container>

            {/* Text and Button */}
            <Box className="hero-section-text-button">
                <p className='hero-home-white-text'>
                    360 digitalization services designed to infuse
                    <br />
                    innovative technologies and integrate cutting
                    <br />
                    edge tools into your business
                   

                </p>
                <div className='button-box'>
                    <ContactButton text="Start Now" mobile={false} backgroundWhite={true}/>
                </div>
            </Box>
        </Box>
    );
};


const AboutSection = () => {
    return (
        <section className="about">
            <h2>Don't let the competition get ahead - we are here <br />
                to help your organization get ahead in the digital <br />
                transformation race</h2>
            <div className="about-grid">
                <div className="about-column">
                    <div className="about-icon1"><img src="images/mission.jpg" alt="Mission Icon" className="image"></img></div>
                    <h3>Mission</h3>
                    <p>We are here to ensure your business processes are properly documented, planned and automated to avoid significant value loss over time and provide ROI on RPA, system integration and overall digital transformation efforts.</p>
                </div>
                <div className="about-column">
                    <div className="about-icon1"><img src="images/value.jpg" alt="Mission Icon" className="image"></img></div>
                    <h3>Value proposition</h3>
                    <p>Let our team's years of experience with the Mulesoft ecosystem accelerate your company's journey to organizational goals and beyond. data/morphosis was conceived to tailor its services to best suit our client's needs, and we are ready to tackle your next challenge, be it through a monthly subscription or a custom dedicated team.</p>
                </div>
                <div className="about-column">
                    <div className="about-icon1"><img src="images/team.jpg" alt="Mission Icon" className="image"></img></div>
                    <h3>Team</h3>
                    <p>Our certified experts are highly skilled in system integration and automation and know all the best practices and follow the latest frameworks to create highly reusable assets that generate exponential business value.</p>
                </div>
            </div>
        </section>
    );
};

const WhatWeDoSection = () => {
    return (
        <Box className="what-we-do-container">
            <Container maxWidth="md" className="what-we-do-content">
                <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
                    What we do
                </Typography>
                <Typography variant="body" paragraph className="section-paragraph">
                    We offer a wide range of custom software development services to help you achieve your digital transformation goals:<br /><br />
                    <b>Business process automation (BPA)</b> - Identify and automate your most time-consuming and error-prone processes<br /><br />
                    <b>System integration</b> - Integrate your on-premises applications with cloud-based applications and data sources to capture and integrate real-time data and gain real-time insights using technologies such as Mulesoft, Python, Apogee, Boomi and more<br /><br />
                    <b>Digital product development</b> - Design and develop and deploy custom applications for any platform, from mobile applications to enterprise business solutions.<br /><br />
                    <b>Digital landscape transformations</b> - Bridge the gap between your as-is and to-be landscapes from plan to production<br /><br />
                </Typography>

                <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
                    What you get
                </Typography>
                <Typography variant="body" paragraph className="section-paragraph">
                    We help businesses automate their processes and connect various applications and data sources, leading to:<br /><br />
                    <b>Improved efficiency and productivity</b> through <b>automated repetitive tasks</b>, freeing up your resources to focus on more strategic work.<br /><br />
                    <b>Enhanced customer experience</b> provided via <b>seamlessly integrated touchpoints and channels</b>, keeping your clients happy and coming back for more.<br /><br />
                    <b>Data-driven decisions</b> unlocked by <b>real-time data and valuable analytic insights</b> that can help you improve your operations and make better decisions.<br /><br />
                    <b>Boosted agility and scalability</b> with <b>automated processes synched across systems</b> that become more agile and responsive to change.​<br /><br />
                </Typography>

                <Typography variant="h4" color="var(--primary-blue)" gutterBottom className="section-title">
                    Why work with data/morphisis?
                </Typography>
                <Typography variant="body" paragraph className="section-paragraph">
                    We are a team of experienced and certified software developers who are passionate about helping businesses achieve their digital transformation goals and committed to providing our clients with the highest quality of service.<br /><br />
                    We offer flexible engagement models to fit your budget and project needs.<br /><br />
                    Contact us today to learn more about how Data/Morphisis can help you digitally transform your business.
                </Typography>
            </Container>
        </Box>
    );
};

const AnimatedRow = () => {
    const firstRow = [
      {
        animationType: 'animation1',
        topText: '6.300 EUR',
        bottomText: 'month',
        paragraphs: ['Basic', '1 request at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution',],
        buttonText: 'Subscribe now',
        buttonUrl: 'View More',
      },
      {
        animationType: 'animation2',
        topText: '8.900 EUR',
        bottomText: 'month',
        paragraphs: ['Premium', '2 requests at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
        buttonText: 'Subscribe now',
        buttonUrl: 'View More',
      },
      {
        animationType: 'animation3',
        topText: '12.600 EUR',
        bottomText: 'month',
        paragraphs: ['Premium+', 'Maintenance & support', '2 requests at a time', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
        buttonText: 'Subscribe now',
        buttonUrl: 'View More',
      },
    ];
    const secondRow = [
        {
            animationType: 'animation4',
            topText: '7.500 EUR',
            bottomText: 'one time purchase',
            paragraphs: ['One Hit Wonder', '1 single request, no commitments', 'Requirements collection and documentation', 'Implementation of new integrations and automations', 'Implementation of significant changes to existing apps', 'UAT support', 'Detailed technical documentation of final solution'],
            buttonText: 'Subscribe now',
            buttonUrl: 'View More',
          },
        ];
    
  
    return (
        <div className='api-connectivity'>
            <Typography variant="h4" className="api-title">
      Monthly system integration subscriptions
        </Typography>     
      <div className="animated-row">
        {firstRow.map((data, index) => (
          <AnimatedComponent key={index} {...data} />
        ))}
      </div>
      <div class="scalable-container">
            <p>                
            Free templates to help you manage your integration projects with every plan
            </p>
            </div>

      <Typography variant="h4" className="api-title">
      One time interventions to automate your business proces
        </Typography>       
      <div className="animated-row">
      {secondRow.map((data, index) => (
        <AnimatedComponent key={index} {...data} />
      ))}
    </div>
    </div>
    );
  };

const APIConnectivity = () => {
    const screenWidth = window.innerWidth;
    const circleDiameter = 200;
    const paddingLR = (screenWidth / 3 - circleDiameter) / 2; // Padding for left & right

    return (
        <Box id="api-connectivity-section" className="api-connectivity">
            <Typography variant="h4" className="api-title">
                Learn how API led connectivity and data/morphosis can transform your organization
            </Typography>

            <div className="api-icons">
                <div className="circle" style={{ margin: `0 ${paddingLR}px` }}>
                    <Typography className="circle-text">40% cost<br />reduction</Typography>
                </div>
                <div className="circle" style={{ margin: `0 ${paddingLR}px` }}>
                    <Typography className="circle-text">Complete<br />control over<br />data & access</Typography>
                </div>
                <div className="circle" style={{ margin: `0 ${paddingLR}px` }}>
                    <Typography className="circle-text">27% faster<br />digitalization</Typography>
                </div>
            </div>
        </Box>
    );
};

const ContactFormSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        details: '',
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value, // Update the relevant field in formData
        });
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send POST request to the API endpoint
            const response = await fetch('https://e-mobile-api-ituma2afya-oa.a.run.app/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), // Send form data as JSON
            });

            if (response.ok) {
                // Handle successful submission (show success message, clear form, etc.)
                console.log('Form submitted successfully!');
            } else {
                // Handle errors
                console.error('Failed to submit the form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <Box id="contact-section" className="contact-form-section">
             <Box className="form-container">
            <Container maxWidth="md" className="form-content">
                <Typography variant="h4" color="white" className="form-title">
                    Get a Personalized Offer
                </Typography>

                <form className="form-fields" onSubmit={handleSubmit}>
                    <Grid container spacing={20}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" color="white" className="form-label">
                                Full Name *
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="e.g. John Doe"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '3px solid white',
                                        color: 'white',
                                    },
                                }}
                                className="input-field"
                            />

                            <Typography variant="body1" color="white" className="form-label">
                                Email *
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="e.g. name@example.com"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '3px solid white',
                                        color: 'white',
                                    },
                                }}
                                className="input-field"
                            />

                            <Typography variant="body1" color="white" className="form-label">
                                Phone
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="e.g. +40712 345 678"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '3px solid white',
                                        color: 'white',
                                    },
                                }}
                                className="input-field"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" color="white" className="form-label">
                                Company
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Company"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '3px solid white',
                                        color: 'white',
                                    },
                                }}
                                className="input-field"
                            />

                            <Typography variant="body1" color="white" className="form-label">
                                Give Us More Details
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                multiline
                                rows={4}
                                name="details"
                                value={formData.details}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '3px solid white',
                                        color: 'white',
                                    },
                                }}
                                className="input-field"
                            />
                        </Grid>
                    </Grid>
                    <div className="contact-parent-home">
                        <button type="submit" className="send-contact-button-home">
                            Request an Offer
                        </button>
                    </div>
                </form>
            </Container>
        </Box>

            <Box className="form-footer">
                <Typography variant="h4" color="white">
                    On average, Mulesoft APIs enable 27% faster automation of <br />
                    business processes, but together we are sure we can do <br />
                    better!
                </Typography>
            </Box>
        </Box>
    );
};

export default HomePage;
