import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import "./navigation-page.css";

const NavigationPage = ({ closeNavigationPage }) => {
    const [flip, setFlip] = useState(false);

    // Trigger flip animation after the component mounts
    useEffect(() => {
        const timeoutId = setTimeout(() => setFlip(true), 10); // Delay to allow the transition
        return () => clearTimeout(timeoutId); // Cleanup when component unmounts
    }, []);

    return (
        <Box className={`navigation-page-mobile ${flip ? 'show' : ''}`} onClick={closeNavigationPage}>
            <Box className="flip-card-mobile">
                <Box className="flip-card-inner-mobile">
                    <Box className="logo-container-mobile">
                        <img src="/images/logo.jpg" alt="Logo" className="logo-mobile" />
                    </Box>
                
                    <Box className="navigation-items-mobile">
                        {[
                            { title: 'Home', path: '/mobile' },
                            { title: 'About', path: '/about-mobile' },
                            { title: 'API powered digital transformation', path: '/API-powered-mobile' },
                            { title: 'Customer centric digital experiences', path: '/digital-experiences-mobile' },
                            { title: 'High powered RPA', path: '/RPA-mobile' },
                            { title: 'Contact', path: '/contact-mobile' },
                        ].map((item) => (
                            <Typography
                                key={item.title}
                                variant="h5"
                                color="white"
                                className="navigation-link-mobile"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent background click event
                                    window.location.href = item.path; // Navigate to the page
                                }}
                            >
                                {item.title}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NavigationPage;
