import React from 'react';
import { Box, Typography } from '@mui/material';
import './digitalExperiences.css';
import AppBarMobile from './components/appBar-mobile';
import Footer from '../desktop/components/footer';
import ContactButton from '../../components/contact-button';

const DigitalExperiencesMobile = () => {
    return (
        <div>
            <AppBarMobile />
            <div className="customer-page-mobile">
                <img src="/images/customer-centric.jpg" alt="About Us" className="customer-image-top-mobile" />
                <Typography className="customer-title-mobile">
                    We build data-driven platforms around your clients, enabling omnichannel experiences
                </Typography>
                <ContactButton text="Start building 360 customer views" mobile={false} backgroundWhite={false}/>
                <p className="customer-header-mobile">
                    From high performing backend systems to cutting-edge front end designs, get the toolkit for digital success
                </p>
                <h4 className="customer-subtitle-mob">
                    Custom software for personalized experiences
                </h4>
                <p className="customer-paragraph-mobile">
                    Delight customers with highly personalized experiences enabled by real time data, beautifully displayed based on their preferences
                </p>
                <h4 className="customer-subtitle-mob">
                    Proprietary software for cutting edge innovation
                </h4>
                <p className="customer-paragraph-mobile">
                    Create the software you need to enable your innovative business models and delivert value on new channels
                </p>
                <h4 styles="customer-subtitle-mob">
                    Cloud platforms with zero downtime
                </h4>
                <p className="customer-paragraph-mobile">
                    Make critical data always available with the enhanced reliability of cross-regional cloud based disaster recovery and high availability
                </p>
                <h4 className="customer-subtitle-mob">
                    Mobile applications for cross-platform competitiveness
                </h4>
                <p className="customer-paragraph-mobile">
                    Create a seamless cross-platform experience for your customers with a behaviour-engineered mobile application
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default DigitalExperiencesMobile;
