import React from 'react';
import './contact-button.css'; // Ensure to create this CSS file

import { useNavigate, useLocation } from 'react-router-dom';

const ContactButton = ({ text, mobile, backgroundWhite }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        const targetSection = document.querySelector('#contact-section');

        // If the section exists, scroll to it directly
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            // If not on the page, navigate to the appropriate URL
            const targetPath = mobile ? '/mobile/#contact-section' : '/home/#contact-section';
            navigate(targetPath, { replace: false });
        }
    };

    return (
        <button className={backgroundWhite ? "reusable-button-white" : "reusable-button"} onClick={handleClick}>
            {text}
        </button>
    );
};

export default ContactButton;
